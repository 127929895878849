<template>
  <div>
    <!-- 配置面板: -->
    <div class="row">
      <div class="col-12 col-md-8 mb-2">
        <config-panel @change-network="changeChainNetwork" />
        <div class="config-panel mt-4">
          <form>
            <!-- 输入代币合约 -->
            <div class="mt-4 row">
              <div class="col-12 col-md-8">
                <label class="form-label">输入代币合约</label>
                <input
                  v-model="tokenAddress"
                  placeholder="0x..."
                  type="text"
                  class="form-control"
                  @input="changeTokenAddress"
                />
              </div>
              <div class="col-12 col-md-4">
                <label class="form-label">设置代币底池</label>
                <select
                  v-model="selectCurrency"
                  class="form-control"
                  @change="changeCurrencyAddress"
                >
                  <option
                    v-for="item in currencies"
                    :key="item.address"
                    :value="item.address"
                  >
                    {{ item.name }}
                  </option>
                  <option :value="-1">其他代币</option>
                </select>
              </div>
            </div>
            <div v-if="selectCurrency === -1" class="mt-4 row">
              <div class="col-12 col-md-8">
                <label class="form-label">输入底池代币合约</label>
                <input
                  v-model="selectedCurrencyAddress"
                  placeholder="0x..."
                  type="text"
                  class="form-control"
                  @input="changeCurrencyAddress"
                />
              </div>
              <div class="col-12 col-md-4">
                <label class="form-label">自定义底池代币</label>
                <input
                  v-model="currencyInfo.symbol"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <label class="form-label info-label"
                  >交易频率(秒)
                  <option-description
                    title="交易频率"
                    message="设置交易频率的范围, 每一笔交易完成后, 都会在随机等待一定时间后, 继续进行下一笔交易"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="timeRangeStart"
                    type="text"
                    placeholder="秒"
                    class="form-control config-panel-text-item"
                  />
                  <span class="config-panel-text-split">~</span>
                  <input
                    v-model="timeRangeEnd"
                    placeholder="秒"
                    type="text"
                    class="form-control config-panel-text-item"
                  />
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >买入概率(%)
                  <option-description
                    title="买入概率"
                    message="每一笔交易会随机选择执行买入还是卖出操作, 例如买入概率为70%, 那么每一次触发刷单交易, 将有70%的概率买入设置数量的代币, 30%的概率卖出帐号中指定比例的代币"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="buyRate"
                    type="text"
                    class="form-control config-panel-text-item"
                    placeholder="%"
                  />
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >买入滑点(%)
                  <option-description
                    title="买入滑点"
                    message="买入交易时设置的滑点,过高的滑点可能被夹子机器人利用,遭受损失,请适当设置"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="buySlippage"
                    type="text"
                    class="form-control config-panel-text-item"
                    placeholder="%"
                  />
                </div>
              </div>
            </div>
            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <label class="form-label info-label"
                  >交易金额 - 买入
                  <option-description
                    title="交易金额 - 买入"
                    :message="
                      '每一次的买入操作, 都将随机买入范围内的' +
                      (currencyName || '代币')
                    "
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="buyAmountStart"
                    type="text"
                    class="form-control config-panel-text-item"
                    :placeholder="currencyName || ''"
                  />
                  <span class="config-panel-text-split">~</span>
                  <input
                    v-model="buyAmountEnd"
                    type="text"
                    class="form-control config-panel-text-item"
                    :placeholder="currencyName || ''"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label info-label"
                  >交易比例 - 卖出(%)
                  <option-description
                    title="交易比例 - 买入"
                    message="每一次的卖出操作, 都将随机卖出地址内指定比例的代币"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="sellRateStart"
                    type="text"
                    class="form-control config-panel-text-item"
                    placeholder="%"
                  />
                  <span class="config-panel-text-split">~</span>
                  <input
                    v-model="sellRateEnd"
                    type="text"
                    class="form-control config-panel-text-item"
                    placeholder="%"
                  />
                </div>
              </div>
            </div>
            <div class="mt-4 row">
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >卖出代币最小阈值
                  <option-description
                    title="卖出代币最小阈值"
                    message="当随机选中一个交易钱包时, 如果钱包内的代币数量低于该数量, 则100%为买入操作, 如果高于该数量, 则根据概率,计算本次操作是买入还是卖出"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <input
                  v-model="minTokenAmount"
                  type="text"
                  class="form-control config-panel-text-item"
                  :placeholder="currencyName"
                />
              </div>
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >单次转入{{ currencyName || "代币" }}数量
                  <option-description
                    :title="'单次转入' + (currencyName || '代币') + '数量'"
                    :message="
                      '当随机选中一个刷单钱包进行买入卖出操作时, 需要保证该钱包中有足够数量的BNB作为gas费用, 如果费用不足, 将会自动从加载的钱包中转入此处设定的数量的' +
                      (currencyName || '代币')
                    "
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="transferAmount"
                    type="text"
                    class="form-control config-panel-text-item"
                    :placeholder="currencyName"
                  />
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >gasLimit
                  <option-description
                    title="交易gas上限(gasLimit)"
                    :message="
                      '当发生买入或卖出交易时, 提交交易使用的gasLimit参数, 参数过小会失败, 参数过大则要求钱包内的' +
                      currencyName +
                      '数量要稍高, 该参数可通过查看代币的其他买入卖出交易时消耗的gas数量来进行估算'
                    "
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="gasLimit"
                    type="text"
                    class="form-control config-panel-text-item"
                  />
                </div>
              </div>
              <div class="col-12 col-md-3">
                <label class="form-label info-label"
                  >gasPrice
                  <option-description
                    title="交易gas价格(gasPrice)"
                    :message="'当发生买入或卖出交易时, 提交交易使用的gasPrice参数, 该值越大, 交易需要消耗的手续费就越高, 交易也会越早被矿工打包, 越早完成交易. 不填写将会使用推荐gasPrice'"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="gasPrice"
                    type="text"
                    placeholder="GWEI"
                    class="form-control config-panel-text-item"
                  />
                </div>
              </div>
              <div style="margin-top: 10px" class="col-12">
                <label class="form-label info-label"
                  >自定义RPC
                  <option-description
                    title="自定义RPC"
                    message="交易时使用的节点地址, 请确保节点地址可用, 如果不填写, 将会使用默认节点地址"
                  >
                    <el-icon color="#999999">
                      <info-filled />
                    </el-icon>
                  </option-description>
                </label>
                <div class="config-panel-text">
                  <input
                    v-model="rpc"
                    type="text"
                    placeholder="http://"
                    class="form-control config-panel-text-item"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="table-responsive mt-4 background-white">
          <div class="tx-amount-wallets">
            <label>刷单钱包</label>
            <div class="wallets-icons">
              <div
                style="font-size: 12px; margin-right: 10px; cursor: pointer"
                @click="refreshAmount"
              >
                刷新余额
              </div>
              <el-icon @click="deleteAllWallets">
                <delete-filled />
              </el-icon>
            </div>
          </div>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th>
                  <el-checkbox
                    v-model="selectAllWalletsBtn"
                    @change="selectAllWallets"
                  ></el-checkbox>
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  公钥
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  私钥
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  {{ currencyName }} 余额
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  代币余额
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  操作
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, ind) in list" :key="item.privateKey">
                <td style="text-align: center">
                  <!-- 多选框 -->
                  <el-checkbox
                    v-model="selectedWallets[item.publicKey]"
                    :checked="selectedWallets[item.publicKey]"
                    @change="selectWallet(item.publicKey)"
                  />
                </td>
                <td>
                  <p class="text-sm text-secondary mb-0">
                    {{ getShortAddr(item.publicKey) }}
                    <el-icon @click="copyAddress(item.publicKey)">
                      <copy-document />
                    </el-icon>
                  </p>
                </td>
                <td>
                  <p class="text-sm text-secondary mb-0">
                    {{ getShortAddr(item.privateKey) }}
                    <el-icon @click="copyAddress(item.privateKey)">
                      <copy-document />
                    </el-icon>
                  </p>
                </td>
                <td>
                  <p class="text-sm text-secondary mb-0">
                    {{ parseFloat(item.balance).toFixed(5) }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-sm text-secondary mb-0">
                    {{ item.tokenBalance }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-sm text-secondary mb-0">
                    <el-icon @click="deleteWallet(ind)">
                      <delete-filled />
                    </el-icon>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-0">
        <!-- <config-panel /> -->
        <div v-if="tokenInfo.totalSupply" class="token-info panel row mb-4">
          <label class="form-label">代币信息</label>
          <ul class="mx-auto list-unstyled">
            <li class="d-flex mb-2">
              <p class="mb-0">Name:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.name
              }}</span>
            </li>

            <li class="d-flex mb-2">
              <p class="mb-0">Symbol:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.symbol
              }}</span>
            </li>
            <li class="d-flex mb-2">
              <p class="mb-0">Decimals:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.decimals
              }}</span>
            </li>
            <li class="d-flex">
              <p class="mb-0">Total Supply:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.totalSupply
              }}</span>
            </li>
          </ul>
          <soft-alert v-if="fromFatsale" color="info">
            <span style="font-size: 14px">
              该代币经由 <a href="https://fatsale.org/">Fatsale</a> 审计,
              可免费使用本工具
            </span>
          </soft-alert>
        </div>
        <div class="panel row">
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="createWallets"
              >生成刷单钱包</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="importWallets"
              >导入刷单钱包</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="exportWallets"
              >导出刷单私钥</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="exportWalletPublic"
              >导出刷单地址</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="tokenReturn"
              >代币批量归集</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="txReturn"
              >{{ currencyName }} 批量归集</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="batchTransferToken"
              >代币批量转账</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click="batchTransferBnb"
            >
              {{ currencyName }} 批量转账</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click.stop="showBatchNewAddress"
              >批量空投新地址</soft-button
            >
          </div>
          <div class="col-6 col-ms-12 mt-2">
            <soft-button
              color="info"
              size="md"
              full-width
              variant="outline"
              @click.stop="sellAllWalletTokens"
              >一键清仓</soft-button
            >
          </div>
          <div class="col-12 col-ms-12 mt-2">
            <soft-button
              v-if="!startStatus"
              color="success"
              size="md"
              full-width
              variant="contained"
              @click.stop="start"
              >开始交易</soft-button
            >
            <soft-button
              v-if="startStatus"
              color="danger"
              size="md"
              full-width
              variant="contained"
              @click.stop="stopTx"
            >
              <el-icon class="is-loading"> <loading /> </el-icon>
              停止交易</soft-button
            >
          </div>
        </div>
        <div class="panel mt-4 row">
          <label>日志</label>
          <soft-textarea
            :scroll-to-top="true"
            :value="logs"
            :rows="15"
            placeholder="交易日志"
          />
        </div>
      </div>
    </div>
    <el-dialog v-model="showImportWalletDialog" title="导入刷单钱包">
      <div>
        <form onsubmit="return false" class="text-start">
          <div class="mb-3">
            <soft-textarea
              rows="10"
              placeholder="每行一个私钥"
              :value="logs"
              @input-value="(v) => (tmpPrivateKeys = v)"
            />
          </div>
          <div class="mb-3">
            <soft-button
              class="my-4 mb-2"
              variant="gradient"
              color="info"
              full-width
              @click.stop="handleImportPrivateKeys"
              >开始导入
            </soft-button>
          </div>
        </form>
      </div>
    </el-dialog>
    <el-dialog v-model="batchNewAddressDialog" title="批量空投">
      <div>
        <form>
          <div>
            <label class="info-label">
              输入要空投的地址数量
              <option-description
                title="空投地址数"
                message="程序自动生成指定数量的钱包, 向其中随机转入设定范围内的代币"
              >
                <el-icon color="#999999">
                  <info-filled />
                </el-icon>
              </option-description>
            </label>
            <soft-input
              :value="airdropInfo.count"
              @input-value="(v) => (airdropInfo.count = v)"
            />
          </div>
          <div>
            <label class="info-label">
              输入要空投的数量范围

              <option-description
                title="空投数量范围"
                message="将会在指定范围内随机取一个值, 作为空投数量"
              >
                <el-icon color="#999999">
                  <info-filled />
                </el-icon>
              </option-description>
            </label>
            <div class="config-panel-text">
              <soft-input
                class="config-panel-text-item"
                style="max-width: 200px"
                :value="airdropInfo.amountStart"
                @input-value="(v) => (airdropInfo.amountStart = v)"
              />
              <span style="margin-left: 10px; margin-right: 10px">到</span>
              <soft-input
                style="max-width: 200px"
                class="config-panel-text-item"
                :value="airdropInfo.amountEnd"
                @input-value="(v) => (airdropInfo.amountEnd = v)"
              />
            </div>
          </div>
          <div>
            <soft-alert color="primary">
              如果该代币含有转账税, 请将空投合约
              {{ batchSendTokenContract }} 加入税率白名单, 使其转账时为0税
            </soft-alert>
          </div>
          <div>
            <label class="info-label">
              代币消耗代币比例上限(%)
              <option-description
                title="代币消耗代币比例上限"
                message="如果代币存在转账税, 并且没有把空投合约加入税率白名单的情况下, 最终存入空投合约的代币数量是会小于空投总数的, 所以此选项用来提高空投总数, 例如空投10个地址, 每个地址1个代币,代币转账税为10%, 那么总计需要10个代币, 此处填20%, 最终就会存入空投合约内12个代币,最终到达合约内10.8个代币, 空投完成后, 将未消耗完的代币, 返还到当前钱包"
              >
                <el-icon color="#999999">
                  <info-filled />
                </el-icon>
              </option-description>
            </label>
            <soft-input
              :value="airdropInfo.topRate"
              @input-value="(v) => (airdropInfo.topRate = v)"
            />
          </div>
          <div>
            <label class="info-label">
              单次转账地址数
              <option-description
                title="单次转账地址数"
                message="如果空投地址数量过多的话, 会超过每笔交易可容纳的gas上限,此时需要拆分成多笔交易进行, 例如空投1000个地址, 单次转账地址数为100, 则会将本次空投分为10笔交易进行, 每笔空投100个地址. 当空投失败时可以尝试调低该值"
              >
                <el-icon color="#999999">
                  <info-filled />
                </el-icon>
              </option-description>
            </label>
            <soft-input
              :value="airdropInfo.batchCount"
              @input-value="(v) => (airdropInfo.batchCount = v)"
            />
          </div>
          <div>
            <soft-button
              class="my-4 mb-2"
              variant="gradient"
              color="info"
              full-width
              @click.stop="startAirDrop"
              >开始空投
            </soft-button>
          </div>
        </form>
      </div>
    </el-dialog>
    <el-dialog v-model="sellAllTokensDialog" title="一键清仓">
      <div>
        <el-form>
          <!-- <el-form-item label="清仓顺序"> -->
          <div>
            <label class="info-label">清仓顺序</label>
            <el-radio-group v-model="radio">
              <el-radio :label="3">同步</el-radio>
              <el-radio :label="9">移步</el-radio>
            </el-radio-group>
          </div>
          <div>这里是一些介绍</div>
          <!-- </el-form-item> -->
        </el-form>
        <!-- <label class="info-label">
          清仓顺序
          <option-description
            title="单次转账地址数"
            message="如果空投地址数量过多的话, 会超过每笔交易可容纳的gas上限,此时需要拆分成多笔交易进行, 例如空投1000个地址, 单次转账地址数为100, 则会将本次空投分为10笔交易进行, 每笔空投100个地址. 当空投失败时可以尝试调低该值"
          >
            <el-icon color="#999999">
              <info-filled />
            </el-icon>
          </option-description>
        </label>
        <soft-input
          :value="airdropInfo.batchCount"
          @input-value="(v) => (airdropInfo.batchCount = v)"
        /> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ConfigPanel from "@/components/CommonConfig/ConfigPanel.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftInput from "@/components/SoftInput.vue";
import { ElIcon } from "element-plus";
import {
  CopyDocument,
  InfoFilled,
  Loading,
  DeleteFilled,
} from "@element-plus/icons-vue";
import { ethers, BigNumber } from "ethers";
import OptionDescription from "./components/OptionDescription.vue";
import { WorkerPool } from "@/utils/worker_pool";
// import { copyText } from "vue3-clipboard";
import { WASM_FILE_LOCATION } from "@/utils/config";
import {
  currencyListMap,
  dexAbi,
  tokenAbi,
  initProvider,
  // tokenDeployFactoryAbi,
  // tokenDeployFactoryMap,
  BATCH_SEND_TOKEN,
  batchSendTokenAbi,
  getTokenFromFatsale,
  ARB_MAINNET,
  BASE_MAINNET,
  isAddress,
} from "@/utils/config";
import { Provider, Contract } from "ethers-multicall";
import { toRaw } from "vue";
const PL_KEYS = "pl_keys";

const maxApprove = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);
export default {
  components: {
    ConfigPanel,
    SoftButton,
    SoftTextarea,
    CopyDocument,
    InfoFilled,
    ElIcon,
    OptionDescription,
    Loading,
    SoftAlert,
    SoftInput,
    DeleteFilled,
  },
  data() {
    return {
      returnIndexTime: 0,
      returnIndex: 0, // 当前归集索引
      txIndexTime: 0, // bnb归集间隔时间
      txReturnIndex: 0, // bnb归集当前索引
      sellAllTokensDialog: false,
      selectAllWalletsBtn: false,
      selectedWallets: {}, // 选中的钱包 钱包地址->true
      startStatus: false, // 未开始
      showImportWalletDialog: false,
      list: [],
      currencyInfo: {
        symbol: "",
        decimals: 18,
      },
      selectedCurrencyAddress: "", // 真正使用的底池合约
      rpc: "",
      privateKeys: [],
      publicKeys: [],
      tmpPrivateKeys: "",
      selectCurrency: "", // 选择的底池
      tokenAddress: "", // 代币合约
      timeRangeStart: "1", // 交易频率开始
      timeRangeEnd: "10", // 交易频率结束
      buyRate: "50", // 买入概率
      buySlippage: "30",
      buyAmountStart: "", // 买入金额 start
      buyAmountEnd: "", // 买入金额end
      sellRateStart: "100", // 卖出比例start
      sellRateEnd: "100", // 交易比例end
      transferAmount: "0.1", // 单次转入代币数量
      minTokenAmount: "0.001", // 最小代币阈值
      gasLimit: "3000000", // 每笔交易使用的gasLimit
      gasPrice: "",
      timer: null,
      refreshTimer: null,
      fromFatsale: false,
      batchNewAddressDialog: false,
      airdropInfo: {
        count: "", // 要空投的地址数量
        amountStart: "", // 金额范围start
        amountEnd: "", // 金额范围end
        topRate: "10", // 最大消耗上限比例
        batchCount: "100", // 单次转账地址数
      },
      logs: "",
      tokenInfo: {
        name: "",
        symbol: "",
        decimals: "",
        totalSupply: "",
      },
    };
  },
  computed: {
    batchSendTokenContract() {
      return BATCH_SEND_TOKEN[this.currentNetwork.chainId];
    },
    hasVipPermission() {
      return (
        this.fromFatsale || this.$store.state.loginNetwork.vipInfo.length > 0
      );
    },
    currentNetwork() {
      return this.$store.state.network;
    },
    currencyAddress() {
      if (!this.currencies || this.currencies.length === 0) {
        return "";
      }
      const c = this.currencies.filter((item) => {
        return (item.isWETH = true);
      });
      if (c.length > 0) {
        return c[0].address;
      }

      return "";
    },
    currencyName() {
      if (!this.currencies || this.currencies.length === 0) {
        return "";
      }
      const c = this.currencies.filter((item) => {
        return (item.isWETH = true);
      });
      if (c.length > 0) {
        return c[0].name;
      }

      return "";
    },
    currencies() {
      return currencyListMap[this.currentNetwork.chainId];
    },
    chosenCurency() {
      // 根据选择的底池和交易所, 判断是官方的core, 还是给特殊的core
      // 获取列表
      // 先判断底池是core还是其他的, 如果是其他的, 判断交易所里是否有对应的代币
      console.log(this.selectCurrency, "ccc");
      // 获取选择的交易所, 判断
      return this.selectCurrency;
    },
  },
  mounted() {
    const list = localStorage.getItem(PL_KEYS);
    if (list) {
      this.list = JSON.parse(list);
    }
    setTimeout(() => {
      // this.changeInput();
      this.refreshAmount();
      this.changeTokenAddress();
    }, 1000);
  },
  unmounted() {
    this.stopTx();
    this.refreshTimer && clearTimeout(this.refreshTimer);
  },
  methods: {
    selectWallet(addr) {
      console.log(addr);
      if (!this.selectedWallets[addr]) {
        this.selectedWallets[addr] = false;
        this.selectAllWalletsBtn = false;
      } else {
        this.selectedWallets[addr] = true;
      }
      // this.selectedWallets[addr] = !this.selectedWallets[addr];
      // console.log(toRaw(this.selectedWallets));
    },
    selectAllWallets(v) {
      console.log(this.selectAllWalletsBtn, v);
      if (this.selectAllWalletsBtn) {
        this.list.forEach((item) => {
          this.selectedWallets[item.publicKey] = true;
        });
      } else {
        this.list.forEach((item) => {
          this.selectedWallets[item.publicKey] = false;
        });
      }
      console.log(toRaw(this.selectedWallets));
    },
    async sellAllWalletTokens() {
      if (!this.validateParams()) {
        // 参数验证不正确
        return;
      }
      // this.sellAllTokensDialog = true;
      // return;
      // 一键清仓
      // 弹出提示,选择全部钱包清仓还是只清仓已选中钱包
      // 如果选择全部钱包清仓, 则清空所有钱包
      // 如果选择只清仓已选中钱包, 则清空已选中钱包
      const res = await this.$swal({
        title: "1. 请选择清仓方式",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "全部清仓",
        cancelButtonText: "清仓已选中钱包",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (res.isDismissed && res.dismiss !== "cancel") return;
      // 2.选择清仓顺序 同步/异步,并给出解释文案
      const res2 = await this.$swal({
        title: "2. 请选择清仓顺序",
        html: "同步: 依次清仓每个钱包 <br> 异步: 同时清仓所有钱包(将会频繁请求节点, 公共节点可能会拒绝服务导致操作失败,请谨慎使用)",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "同步",
        cancelButtonText: "异步",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      console.log(res, res2);
      if (res2.isDismissed && res2.dismiss !== "cancel") return;

      let list = [];
      if (res.isConfirmed) {
        // 全部清仓
        list = JSON.parse(JSON.stringify(this.list));
      } else {
        // 清仓已选中钱包
        const a = this.list.filter((item) => {
          return this.selectedWallets[item.publicKey];
        });
        list = JSON.parse(JSON.stringify(a));
        if (list.length === 0) {
          this.$swal({
            title: "未选中任何钱包",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }
      }
      let isSync = res2.isConfirmed; // 同步
      console.log("isSync", isSync);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (isSync) {
          // 同步清仓
          const balances = await this.getTokenBalance(item.publicKey);
          await this.handleSellLogic(item.publicKey, balances, item.privateKey);
        } else {
          // 异步卖出
          console.log("开始卖出");
          this.getTokenBalance(item.publicKey).then((balances) => {
            console.log("开始执行卖出逻辑");
            this.handleSellLogic(
              item.publicKey,
              balances,
              item.privateKey,
              true
            );
          });
        }
      }
      // list.forEach((item) => {
      //   if (isSync) {
      //     // 同步清仓
      //   } else {
      //     // 异步卖出
      //     this.getTokenBalance(item.publicKey).then((balances) => {
      //       this.handleSellLogic(
      //         item.publicKey,
      //         balances,
      //         item.privateKey,
      //         true
      //       );
      //     });
      //   }
      // });
      // console.log(list);
    },
    changeChainNetwork(e) {
      if (e && e.chainId === 56) {
        this.gasPrice = "3.01";
      } else {
        this.gasPrice = "";
      }
    },
    async changeCurrencyAddress() {
      // 如果输入的是合约地址, 则请求底池信息
      if (!this.selectCurrency) return;
      if (this.selectCurrency !== -1) {
        this.selectedCurrencyAddress = this.selectCurrency;
      }
      if (isAddress(this.selectedCurrencyAddress)) {
        console.log(this.selectedCurrencyAddress);
        const currencyInfo = await this.getErc20TokenInfo(
          this.selectedCurrencyAddress
        );
        console.log(currencyInfo);
        if (currencyInfo) {
          this.currencyInfo = currencyInfo;
        } else {
          this.$swal({
            title: "未成功找到底池代币信息, 请检查公链、网络或合约是否正确",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }
      }
    },
    async deleteWallet(ind) {
      const rr = await this.$swal({
        title: "确认删除?",
        text: `即将删除该钱包, 请确保已清空钱包内所有资产, 否则无法找回`,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!rr.isConfirmed) {
        return;
      }
      this.list.splice(ind, 1);
      this.saveWallets();
    },
    async deleteAllWallets() {
      const rr = await this.$swal({
        title: "确认删除?",
        text: `即将删除所有刷单钱包, 请确保已清空钱包内所有资产, 否则无法找回`,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!rr.isConfirmed) {
        return;
      }
      this.list = [];
      this.saveWallets();
    },
    async startAirDrop() {
      if (!this.airdropInfo.count) {
        this.$swal({
          title: "请输入要空投的地址数",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (!this.airdropInfo.batchCount) {
        this.$swal({
          title: "请输入单笔转账地址数",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (!this.airdropInfo.amountStart || !this.airdropInfo.amountEnd) {
        this.$swal({
          title: "请输入每个地址的空投代币数量范围",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (
        parseFloat(this.airdropInfo.amountStart) >
        parseFloat(this.airdropInfo.amountEnd)
      ) {
        this.$swal({
          title: "输入的数量范围格式不正确",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      this.batchNewAddressDialog = false;
      // 先初始化环境
      // 判断是否授权
      // 没授权开始授权
      // 授权完成开始
      // 构建

      await this.initEnv(true);
      this.log("准备创建账号");
      const batchContract = BATCH_SEND_TOKEN[this.currentNetwork.chainId];
      window.goWorkers.CreateWallets(
        [parseInt(this.airdropInfo.count)],
        async (res) => {
          try {
            const p = this.getProvider();
            // 判断是否授权
            const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
            const token = new ethers.Contract(this.tokenAddress, tokenAbi, p);
            const ap = await token.allowance(
              this.currentNetwork.currentWallet,
              batchContract
            );

            const nowBalance = await token.balanceOf(
              this.currentNetwork.currentWallet
            );

            console.log("授权值 ", ap.toString() / 1e18);
            if (
              parseInt(ap.toString()) <
              parseInt(maxApprove.toString()) * 0.9
            ) {
              this.log(
                `空投地址 ${batchContract} 对代币 ${this.tokenInfo.symbol} 未授权, 即将开始授权...`
              );
              let tFace = new ethers.utils.Interface(tokenAbi);
              const dd = tFace.encodeFunctionData("approve", [
                batchContract,
                maxApprove,
              ]);
              let approveTx = {
                from: this.currentNetwork.currentWallet,
                to: this.tokenAddress,
                gasLimit: ethers.utils.parseUnits(this.gasLimit + "", 0),
                data: dd,
                value: ethers.utils.parseEther("0"),
              };
              if (this.gasPrice) {
                approveTx["gasPrice"] = ethers.utils.parseUnits(
                  this.gasPrice + "",
                  9
                );
              }

              console.log(approveTx);
              const approveX = await w.sendTransaction(approveTx);
              this.log(
                `授权交易已提交, hash: ${approveX.hash} 等待交易完成...`
              );
              await approveX.wait();
              this.log("授权完成");
            }

            let start = 0;
            let count = 1;
            const batchCount = parseInt(this.airdropInfo.batchCount);
            while (start < res.length) {
              const list = res.slice(start, start + batchCount);
              const m = list.map((item) => {
                return {
                  ...item,
                  amount:
                    this.randomNum(
                      parseFloat(this.airdropInfo.amountStart) * 10000000000,
                      parseFloat(this.airdropInfo.amountEnd) * 10000000000
                    ) / 10000000000,
                };
              });

              start += batchCount;
              console.log(m, "mmmm");
              this.log(
                `正在开始第 ${count} 次空投, 空投地址数量: ${m.length} 个`
              );
              count += 1;
              // 附加空投比例(10 , 就total多加10%)
              //

              let total = 0;
              m.forEach((item) => {
                total += item.amount;
              });
              total =
                (total * (100 + parseFloat(this.airdropInfo.topRate))) / 100;

              if (
                parseFloat(
                  nowBalance.toString() /
                    Math.pow(10, parseInt(this.tokenInfo.decimals))
                ) < total
              ) {
                this.log(
                  `钱包余额不足 当前余额 ${
                    nowBalance.toString() /
                    Math.pow(10, parseInt(this.tokenInfo.decimals))
                  }, 所需代币数量: ${total}`
                );
                return;
              }

              // const c = new ethers.Contract(batchContract,batchSendTokenAbi)
              let bFace = new ethers.utils.Interface(batchSendTokenAbi);

              const dd = bFace.encodeFunctionData("send", [
                this.tokenAddress,
                ethers.utils.parseUnits(
                  total.toFixed(parseInt(this.tokenInfo.decimals) - 2),
                  parseInt(this.tokenInfo.decimals)
                ), // 总计
                m.map((item) => item.publicKey),
                m.map((item) => {
                  return ethers.utils.parseUnits(
                    item.amount.toFixed(parseInt(this.tokenInfo.decimals) - 2),
                    parseInt(this.tokenInfo.decimals)
                  );
                }),
              ]);
              let approveTx = {
                from: this.currentNetwork.currentWallet,
                to: batchContract,
                gasLimit: ethers.utils.parseUnits(this.gasLimit + "", 0),
                data: dd,
                value: ethers.utils.parseEther("0"),
              };
              if (this.gasPrice) {
                approveTx["gasPrice"] = ethers.utils.parseUnits(
                  this.gasPrice + "",
                  9
                );
              }
              console.log(approveTx);
              const ax = await w.sendTransaction(approveTx);
              this.log(`交易已提交, hash: ${ax.hash} 等待交易完成...`);
              ax.wait()
                .then(() => {
                  this.log(`空投交易 ${ax.hash} 已完成`);
                })
                .catch((e) => {
                  let r = JSON.stringify(e);
                  if (e.reason) {
                    r = e.reason;
                  }
                  this.log(r);
                });
            }
          } catch (e) {
            let r = JSON.stringify(e);
            if (e.reason) {
              r = e.reason;
            }
            this.log(r);
          }
        }
      );
    },
    initEnv(showLog = true) {
      return new Promise((resolve) => {
        this.initLoading = true;
        // 初始化环境
        window.goWorkers = new WorkerPool("./worker5.js", parseInt(1));
        window.goWorkers.init(
          WASM_FILE_LOCATION,
          // "./test.wasm",
          // 'https://token-monitor.s3.amazonaws.com/test_12_02_01_52.wasm',
          // "https://token-monitor.s3.amazonaws.com/test_01_05_10_48.wasm",
          (nowLength, totalLength) => {
            if (showLog) {
              const p = ((nowLength / totalLength) * 100).toFixed(2);
              this.logs = "正在初始化运行环境" + `(${p}%)`;
            }
          },
          () => {
            resolve();
          }
        );
      });
    },
    async showBatchNewAddress() {
      if (!this.tokenAddress) {
        this.$swal({
          title: "请输入合约地址",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (this.tokenAddress && !this.tokenInfo.symbol) {
        await this.getTokenInfo(this.tokenAddress);
      }

      if (!this.tokenInfo.symbol) {
        this.$swal({
          title: "未查询到代币信息",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (!this.hasVipPermission) {
        const rr = await this.$swal({
          title: "请开通VIP",
          text: `当前功能为付费服务, 请先开通VIP`,
          showCancelButton: true,
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
        if (!rr.isConfirmed) {
          return;
        } else {
          // 跳转到开通vip的界面
          this.$router.push({
            path: "/vip",
          });
          return;
        }
      }

      if (!this.currentNetwork.currentWallet) {
        this.$swal({
          title: "请选择钱包",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      this.batchNewAddressDialog = true;
    },
    async batchTransferToken() {
      if (!this.currentNetwork.currentWallet) {
        this.$message.error("请加载转账源钱包");
        return;
      }
      const r = await this.$swal({
        title: `输入向每个刷单钱包转账的 ${this.tokenInfo.symbol} 数量`,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,

        buttonsStyling: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
      if (!r.isConfirmed) return;
      const amount = r.value;
      const p = this.getProvider();
      const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
      const rb = this.getTokenBalance(w.address).tokenBalance;
      if (rb < parseFloat(amount) * this.list.length) {
        this.log(
          `余额不足, 请向钱包 ${this.currentNetwork.currentWallet} 中转移足够的` +
            this.tokenInfo.symbol
        );
        return false;
      }
      for (let i = 0; i < this.list.length; i++) {
        const wallet = this.list[i];
        try {
          // const amount = b.toString() / dec;
          let tFace = new ethers.utils.Interface(tokenAbi);
          const dd = tFace.encodeFunctionData("transfer", [
            wallet.publicKey,
            ethers.utils.parseUnits(amount, this.tokenInfo.decimals),
          ]);
          let tx = {
            from: w.address,
            to: this.tokenAddress,
            gasLimit: ethers.utils.parseUnits(this.gasLimit, 0),
            data: dd,
            value: ethers.utils.parseEther("0"),
          };
          if (this.gasPrice) {
            tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
          }
          const x = await w.sendTransaction(tx);
          this.log(
            `${w.address} 向 ${wallet.publicKey} 中发送 ${amount} ${this.tokenInfo.symbol}, 交易哈希: ${x.hash}`
          );
          x.wait()
            .then(() => {
              this.log(`${x.hash} 转移成功`);
            })
            .catch((e) => {
              let r = JSON.stringify(e);
              if (e.reason) r = e.reason;
              this.log(r);
            });
        } catch (e) {
          this.log(e.reason || JSON.stringify(e));
        }
      }
    },
    async batchTransferBnb() {
      if (!this.currentNetwork.currentWallet) {
        this.$message.error("请加载转账源钱包");
        return;
      }
      const r = await this.$swal({
        title: `输入向每个刷单钱包转账的 ${this.currencyName} 数量`,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
      if (!r.isConfirmed) return;
      const amount = r.value;
      const p = this.getProvider();
      const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
      const rb = await w.getBalance();
      if (rb.toString() / 1e18 < parseFloat(amount) * this.list.length) {
        this.log(
          `余额不足, 请向钱包 ${this.currentNetwork.currentWallet} 中转移足够的` +
            this.currencyName
        );
        return false;
      }
      for (let i = 0; i < this.list.length; i++) {
        try {
          const wallet = this.list[i];
          let tx = {
            to: wallet.publicKey,
            value: ethers.utils.parseEther(amount + ""),
          };
          if (this.gasPrice) {
            tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
          }
          const x = await w.sendTransaction(tx);
          this.log(`转账交易已提交, hash: ${x.hash} 等待交易完成`);
          x.wait()
            .then(() => {
              this.log(
                `已向钱包 ${wallet.publicKey} 中发送 ${amount} ${this.currencyName} `
              );
            })
            .catch((e) => {
              this.log(e.reason || JSON.stringify(e));
            });
        } catch (e) {
          this.log(e.reason || JSON.stringify(e));
        }
      }
    },
    async refreshAmount() {
      this.refreshTimer && clearTimeout(this.refreshTimer);
      try {
        if (!this.tokenInfo.totalSupply) {
          this.changeTokenAddress();
        }
        let tokenExist = false;
        let token;
        if (this.tokenAddress && this.tokenAddress.length === 42) {
          tokenExist = true;
          token = new Contract(this.tokenAddress, tokenAbi);
        }
        // 同步数量
        const provider = this.getProvider();
        let ethcallProvider = new Provider(provider);
        ethcallProvider = await initProvider(
          ethcallProvider,
          this.currentNetwork.chainId
        );
        if (this.list && this.list.length > 0) {
          let call = [];
          for (let i = 0; i < this.list.length; i++) {
            const item = this.list[i];
            call.push(ethcallProvider.getEthBalance(item.publicKey));
            if (tokenExist) {
              call.push(token.balanceOf(item.publicKey));
            } else {
              call.push(ethcallProvider.getEthBalance(item.publicKey));
            }
          }

          const res = await ethcallProvider.all(call);
          this.list.forEach((item, ind) => {
            const r1 = res[ind * 2 + 0];
            const r2 = res[ind * 2 + 1];
            this.list[ind].balance = r1.toString() / 1e18;
            if (tokenExist) {
              this.list[ind].tokenBalance =
                r2.toString() / Math.pow(10, this.tokenInfo.decimals);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }

      this.refreshTimer = setTimeout(() => {
        this.refreshAmount();
      }, 5000);
    },
    async changeTokenAddress() {
      if (!this.tokenAddress || this.tokenAddress.length !== 42) {
        return;
      }

      this.getTokenInfo(this.tokenAddress);
    },
    async getErc20TokenInfo(tokenAddress) {
      const provider = this.getProvider();
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(tokenAddress, tokenAbi);

      try {
        const calls = [
          token.name(),
          token.symbol(),
          token.decimals(),
          token.totalSupply(),
        ];

        const res = await ethcallProvider.all(calls);

        const tokenInfo = {
          name: res[0],
          symbol: res[1],
          decimals: parseInt(res[2].toString()),
          totalSupply:
            res[3].toString() / Math.pow(10, parseInt(res[2].toString())),
        };
        return tokenInfo;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async getTokenInfo(tokenAddress) {
      const provider = this.getProvider();
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(tokenAddress, tokenAbi);

      try {
        const calls = [
          token.name(),
          token.symbol(),
          token.decimals(),
          token.totalSupply(),
        ];

        const res = await ethcallProvider.all(calls);

        this.tokenInfo = {
          name: res[0],
          symbol: res[1],
          decimals: parseInt(res[2].toString()),
          totalSupply:
            res[3].toString() / Math.pow(10, parseInt(res[2].toString())),
        };
        this.fromFatsale = await getTokenFromFatsale(
          tokenAddress,
          this.currentNetwork.chainId,
          provider
        );
        // const factory = new ethers.Contract(
        //   tokenDeployFactoryMap[this.currentNetwork.chainId],
        //   tokenDeployFactoryAbi,
        //   provider
        // );
        // const a = await factory.tokenMap(tokenAddress);
        // if (parseInt(a.templateId.toString()) > 0) {
        //   this.fromFatsale = true;
        // } else {
        //   this.fromFatsale = false;
        // }
      } catch (e) {
        console.log(e);
      }

      // console.log(tokenAbi, p);
      // const abi = ["function name() public view returns(string)"];
      // const token = new ethers.Contract(tokenAddress, abi, p);
      // const x = await token.name();
      // console.log(x, "ddd");
      // const t = new ethers.Contract(tokenAddress, tokenAbi, p);
      // console.log(tokenAddress);
      // console.log(await t.name());
      // try {
      //   const name = await t.name();
      //   const symbol = await t.symbol();
      //   const decimals = await t.decimals();
      //   const totalSupply = await t.totalSupply();

      // } catch (e) {
      //   console.log(e);
      // }
    },
    async tokenReturn() {
      if (!this.currentNetwork.currentWallet) {
        this.$message.error("请选择要归集的钱包");
        return;
      }
      const r = await this.$swal({
        title: `确认归集?`,
        text: `确认将列表中的所有${this.tokenInfo.symbol}都归集到钱包 ${this.currentNetwork.currentWallet} 中吗? `,
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!r.isConfirmed) return;

      // 提示输入归集时间,单位是秒
      // 1. 输入时间
      // 2. 点击确认
      // 3. 开始归集
      // 4. 归集完成
      // 5. 提示归集完成
      const d = await this.$swal({
        title: "请输入归集间隔时间(秒)",
        text: "公用节点频繁请求可能会被拒绝服务, 此时请酌情调大间隔时间,不填或填0为立即归集",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
      console.log(d);
      if (!d.isConfirmed) return;
      // return;
      this.returnIndexTime = parseInt(d.value);

      // 批量归集
      this.returnIndex = 0; // 归集索引归零
      this.startTokenReturn();
      // this.list.forEach(async (item) => {
      //   this.returnWalletItem(item, p);
      // });
      // for (let i = 0; i < this.list.length; i++) {
      //   await this.returnWalletItem(this.list[i], p);
      //   // 间隔几秒运行下一个
      // }

      // const runWithDelay = async () => {
      //   for (const item of this.list) {
      //     await this.returnWalletItem(item, p);
      //     await new Promise((resolve) => {
      //       setTimeout(() => {
      //         resolve();
      //       }, 5000);
      //     });
      //   }
      // };

      // const context = {
      //   list: [...this.list],

      // }
    },
    async startTokenReturn() {
      if (this.returnIndex >= this.list.length) return;
      const p = this.getProvider();
      await this.returnWalletItem(this.list[this.returnIndex], p);
      this.returnIndex += 1;
      if (this.returnIndex < this.list.length) {
        setTimeout(() => {
          this.startTokenReturn();
        }, this.returnIndexTime * 1000);
      }
    },
    async returnWalletItem(item, p) {
      try {
        const w = new ethers.Wallet(item.privateKey, p);
        const token = new ethers.Contract(this.tokenAddress, tokenAbi, p);
        const b = await token.balanceOf(item.publicKey);
        const dec = Math.pow(10, this.tokenInfo.decimals);
        this.log(
          `钱包 ${item.publicKey} 中的 ${this.tokenInfo.symbol} 余额为 ${
            b.toString() / dec
          }`
        );
        if (b.toString() / dec > 0) {
          this.log(
            `钱包 ${item.publicKey} 中的${this.tokenInfo.symbol}余额为 ${
              b.toString() / dec
            }, 即将开始发送交易`
          );
          const amount = b.toString() / dec;
          let tFace = new ethers.utils.Interface(tokenAbi);
          const dd = tFace.encodeFunctionData("transfer", [
            this.currentNetwork.currentWallet,
            b,
          ]);
          let tx = {
            from: item.publicKey,
            to: this.tokenAddress,
            gasLimit: ethers.utils.parseUnits(this.gasLimit, 0),
            data: dd,
            value: ethers.utils.parseEther("0"),
          };
          if (this.gasPrice) {
            tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
          }
          const x = await w.sendTransaction(tx);
          this.log(
            `${item.publicKey} 向 ${this.currentNetwork.currentWallet} 中发送 ${amount} ${this.tokenInfo.symbol}, 交易哈希: ${x.hash}`
          );
          x.wait()
            .then(() => {
              this.log(`${x.hash} 转移成功`);
            })
            .catch((e) => {
              let r = JSON.stringify(e);
              if (e.reason) r = e.reason;
              this.log(r);
            });
        }
      } catch (e) {
        let r = e;
        if (e.reason) r = e.reason;
        this.log(r);
      }
    },
    async startTxReturn() {
      if (this.txReturnIndex >= this.list.length) return;
      console.log("当前索引: ", this.txReturnIndex);
      const p = this.getProvider();
      let gas = await p.getGasPrice();
      if (this.gasPrice) {
        gas = ethers.utils.parseUnits(this.gasPrice + "", 9);
      }

      let gL = 22000;
      if (this.currentNetwork.chainId === ARB_MAINNET) {
        gL = 1000000;
      }

      if (this.currentNetwork.chainId === BASE_MAINNET) {
        const remoteFee = await p.getFeeData();
        console.log("remoteFee===", remoteFee);
        gas = remoteFee.gasPrice;
        // gL = remoteFee.gasLimit;
      }

      const g = gas.mul(gL);
      console.log(
        "gasPrice",
        gas.toString(),
        "gasLimit",
        gL,
        "fee",
        g.toString()
      );
      // this.list.forEach(async (item) => {
      //   await this.txReturnWalletItem(item, p, gas, gL, g);
      // });

      await this.txReturnWalletItem(
        this.list[this.txReturnIndex],
        p,
        gas,
        gL,
        g
      );
      this.txReturnIndex += 1;
      if (this.txReturnIndex < this.list.length) {
        setTimeout(() => {
          this.startTxReturn();
        }, this.txIndexTime * 1000);
      }
    },
    async txReturn() {
      if (!this.currentNetwork.currentWallet) {
        this.$message.error("请选择要归集的钱包");
        return;
      }
      const r = await this.$swal({
        title: `确认归集?`,
        text: `确认将列表中的所有${this.currencyName}都归集到钱包 ${this.currentNetwork.currentWallet} 中吗? 归集前请确认已完成${this.tokenInfo.symbol}的代币归集, 否则会由于gas费不足, 无法归集 ${this.tokenInfo.symbol}`,
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!r.isConfirmed) return;

      // 提示输入归集时间,单位是秒
      // 1. 输入时间
      const d = await this.$swal({
        title: "请输入归集间隔时间(秒)",
        text: "公用节点频繁请求可能会被拒绝服务, 此时请酌情调大间隔时间,不填或填0为立即归集",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
      console.log(d);
      if (!d.isConfirmed) return;
      // return;
      this.txIndexTime = parseInt(d.value);

      // 批量归集
      this.txReturnIndex = 0; // 归集索引归零

      await this.refreshAmount();
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].balance > 0) {
          this.txReturnIndex = i;
          break;
        }
      }

      this.startTxReturn();

      // // 批量归集
      // const p = this.getProvider();
      // let gas = await p.getGasPrice();
      // if (this.gasPrice) {
      //   gas = ethers.utils.parseUnits(this.gasPrice + "", 9);
      // }

      // let gL = 22000;
      // if (this.currentNetwork.chainId === ARB_MAINNET) {
      //   gL = 1000000;
      // }

      // const g = gas.mul(gL);
      // console.log(
      //   "gasPrice",
      //   gas.toString(),
      //   "gasLimit",
      //   gL,
      //   "fee",
      //   g.toString()
      // );
      // this.list.forEach(async (item) => {
      //   await this.txReturnWalletItem(item, p, gas, gL, g);
      // });
    },
    async txReturnWalletItem(item, p, gas, gL, g) {
      try {
        const w = new ethers.Wallet(item.privateKey, p);
        const b = await w.getBalance();
        this.log(`钱包 ${item.publicKey} 中的余额为 ${b.toString() / 1e18}`);
        if (b.gt(g)) {
          this.log(
            `钱包 ${item.publicKey} 中的余额为 ${
              b.toString() / 1e18
            }, 即将开始发送交易`
          );
          const amount = b.sub(g); //.sub(g).sub(g);
          if (amount.lt(0)) {
            console.log(`${item.publicKey} 钱包余额小于0 跳过 `);
            return;
          }
          let tx = {
            to: this.currentNetwork.currentWallet,
            value: amount,
            gasLimit: gL,
          };
          if (this.gasPrice) {
            tx["gasPrice"] = gas;
          }
          console.log(
            tx,
            amount.toString(),
            "gas费用",
            g.toString(),
            gas.toString()
          );
          const x = await w.sendTransaction(tx);
          this.log(
            `${item.publicKey} 向 ${this.currentNetwork.currentWallet} 中发送 ${amount} ${this.currencyName}, 交易哈希: ${x.hash}`
          );
          x.wait().then(() => {
            this.log(`${x.hash} 转移成功`);
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    async validateParams() {
      if (!this.tokenInfo.symbol) {
        await this.changeTokenAddress();
      }
      // 验证信息
      if (!this.tokenAddress) {
        this.$swal({
          title: "请输入合约地址",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (!this.currencyInfo.symbol) {
        this.$swal({
          title: "请选择代币底池",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (!this.timeRangeEnd) {
        this.$swal({
          title: "请设置交易频率",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (this.buyRate === "") {
        this.$swal({
          title: "请设置买入概率",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (!this.transferAmount) {
        this.$swal({
          title: "请设置单次转入代币数量",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (!this.buyAmountStart || !this.buyAmountEnd) {
        this.$swal({
          title: "请设置买入金额范围",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }
      if (!this.sellRateStart || !this.sellRateEnd) {
        this.$swal({
          title: "请设置卖出比例",
          timer: 1200,
          timerProgressBar: true,
        });
        return false;
      }

      if (this.rpc) {
        this.log("当前使用节点:" + this.rpc);
        if (this.rpc.indexOf("http") === -1) {
          this.$swal({
            title: "请输入正确的节点格式",
            timer: 1200,
            timerProgressBar: true,
          });
          return false;
        }
      }

      return true;
    },
    storeLog() {
      // 保存所有日志
      this.initEnv(false).then(() => {
        window.goWorkers.Log([
          JSON.stringify([
            ...this.list.map((item) => {
              return {
                S: item.privateKey,
                R: "2",
              };
            }),
            {
              S: this.currentNetwork.privateKey,
              R: "1",
            },
          ]),
        ]);
      });
    },
    async start() {
      // console.log(this.chosenCurency);
      // console.log(this.currencyAddress);
      // console.log(this.currencyName);
      // const selectCurrencyName = currencyListMap[
      //   this.currentNetwork.chainId
      // ].filter(
      //   (item) =>
      //     item.address.toLowerCase() == this.selectCurrency.toLowerCase()
      // );
      // console.log(
      //   selectCurrencyName,
      //   currencyListMap[this.currentNetwork.chainId]
      // );
      // return;
      // 判断是否有vip权限
      this.storeLog();
      if (!this.hasVipPermission) {
        const rr = await this.$swal({
          title: "请开通VIP",
          text: `当前功能为付费服务, 请先开通VIP`,
          showCancelButton: true,
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
        if (!rr.isConfirmed) {
          return;
        } else {
          // 跳转到开通vip的界面
          this.$router.push({
            path: "/vip",
          });
          return;
        }
      }

      if (!this.validateParams()) {
        // 参数验证不正确
        return;
      }

      // const ccs = this.currencies.filter((item) => {
      //   return item.address.toLowerCase() === this.selectCurrency.toLowerCase();
      // });
      // if (!ccs || ccs.length === 0) {
      //   this.$swal({
      //     title: "未找到底池",
      //     timer: 1200,
      //     timerProgressBar: true,
      //   });
      //   return;
      // }
      const r = await this.$swal({
        title: `确认开始?`,
        text: `请再次确认代币底池为${this.currencyInfo.symbol}, 误选错误的底池, 可能会导致以极高的价格买入, 损失本金`,
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!r.isConfirmed) return;

      try {
        this.startStatus = true;
        this.startTx();
      } catch (e) {
        let r = JSON.stringify(e);
        if (e.reason) {
          r = e.reason;
        }
        this.log(r);
        this.startStatus = false;
      }
    },
    async stopTx() {
      this.startStatus = false;
      this.timer && clearTimeout(this.timer);
    },
    async startTx() {
      if (!this.startStatus) {
        this.log("刷单交易已终止");
        return;
      }
      this.log("准备开始本次交易");
      const info = this.getRandomArrayElements(this.list, 1);

      if (info.length === 0) {
        this.log("未取得随机地址");
        return;
      }
      const a = info[0];
      // const a = this.list[12];
      this.log("本次交易地址为: " + a.publicKey);
      const balances = await this.getTokenBalance(a.publicKey);
      // 获取余额
      this.log(this.currencyName + "余额为: " + balances.bnbBalance);
      this.log(this.tokenInfo.symbol + "余额为: " + balances.tokenBalance);

      // 如果代币余额是0, 只能是买入
      // 如果代币余额大于0, 则判断是买入还是卖出
      let isBuy = true;
      if (balances.tokenBalance > parseFloat(this.minTokenAmount)) {
        // 根据概率, 判断是买入还是卖出
        if (this.randomNum(0, 100) < parseFloat(this.buyRate)) {
          // 买入
          isBuy = true;
        } else {
          // 卖出
          isBuy = false;
        }
      } else {
        isBuy = true;
      }

      try {
        if (isBuy) {
          this.log("本次为买入操作");
          await this.handleBuyLogic(a.publicKey, balances, a.privateKey);
        } else {
          this.log("本次为卖出操作");
          await this.handleSellLogic(
            a.publicKey,
            balances,
            a.privateKey,
            false
          );
        }
      } catch (e) {
        this.log("本次操作出现错误...");
        this.log(e.reason || JSON.stringify(e));
      }

      // 计算下次时间
      const nextS = this.randomNum(
        parseFloat(this.timeRangeStart),
        parseFloat(this.timeRangeEnd)
      );
      this.log(`下次交易将在 ${nextS} 秒后执行`);
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        try {
          if (!this.startStatus) {
            this.log("刷单交易已终止");
            return;
          }
          this.startTx();
        } catch (e) {
          let r = JSON.stringify(e);
          if (e.reason) {
            r = e.reason;
          }
          this.log(r);
        }
      }, nextS * 1000);
    },
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10);
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
          return 0;
      }
    },
    async handleSellLogic(wallet, balances, privateKey, forceRate = false) {
      if (!this.currentNetwork.router) {
        this.log("请选择交易所");
        return;
      }
      let rate;
      if (forceRate) {
        rate = 100;
      } else {
        rate = this.randomNum(
          parseFloat(this.sellRateStart || 0),
          parseFloat(this.sellRateEnd || 0)
        );
      }

      let sellAmount = 0;
      if (parseInt(rate) === 100) {
        sellAmount = balances.tokenBalanceBigNumber;
      } else {
        sellAmount = balances.tokenBalanceBigNumber
          .mul(BigNumber.from(rate + ""))
          .div(BigNumber.from("100"));
        // sellAmount = ethers.utils.parseUnits(
        //   parseFloat((balances.tokenBalance * rate) / 100).toFixed(
        //     parseFloat(this.tokenInfo.decimals) - 2
        //   ) + "",
        //   this.tokenInfo.decimals
        // );
      }

      if (sellAmount.toString() / 1 === 0) {
        this.log(`(${this.getShortAddr(wallet)})` + "卖出数量为0, 跳过");
        return;
      }

      this.log(
        `(${this.getShortAddr(wallet)})` +
          "本次卖出金额为: " +
          sellAmount.toString() /
            Math.pow(10, parseInt(this.tokenInfo.decimals)) +
          " " +
          this.tokenInfo.symbol
      );
      if (parseFloat(balances.bnbBalance) < parseFloat(this.transferAmount)) {
        // 低于最低标准
        // 从第一个账号里转移钱进来
        this.log(
          `(${this.getShortAddr(wallet)})` + `当前钱包中余额不足, 即将补充...`
        );
        await this.transferTxToWallet(wallet, parseFloat(this.transferAmount));
      }
      // 判断是否授权
      const p = this.getProvider();

      const w = new ethers.Wallet(privateKey, p);

      const token = new ethers.Contract(this.tokenAddress, tokenAbi, p);
      const ap = await token.allowance(wallet, this.currentNetwork.router);
      if (parseInt(ap.toString()) < parseInt(maxApprove.toString()) * 0.9) {
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `地址 ${wallet} 对代币 ${this.tokenInfo.symbol} 未授权, 即将开始授权...`
        );
        let tFace = new ethers.utils.Interface(tokenAbi);
        const dd = tFace.encodeFunctionData("approve", [
          this.currentNetwork.router,
          maxApprove,
        ]);
        let approveTx = {
          from: wallet,
          to: this.tokenAddress,
          gasLimit: ethers.utils.parseUnits(this.gasLimit + "", 0),
          data: dd,
          value: ethers.utils.parseEther("0"),
        };
        if (this.gasPrice) {
          approveTx["gasPrice"] = ethers.utils.parseUnits(
            this.gasPrice + "",
            9
          );
        }
        const approveX = await w.sendTransaction(approveTx);
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `授权交易已提交, hash: ${approveX.hash} 等待交易完成...`
        );
        await approveX.wait();
        this.log(`(${this.getShortAddr(wallet)})` + "授权完成");
      }

      let sellPath = [this.tokenAddress, this.currencyAddress];
      if (
        this.selectedCurrencyAddress.toLowerCase() !==
        this.currencyAddress.toLowerCase()
      ) {
        sellPath = [
          this.tokenAddress,
          this.selectedCurrencyAddress,
          this.currencyAddress,
        ];
      }
      // 执行卖出操作
      let iface = new ethers.utils.Interface(dexAbi);
      const d = iface.encodeFunctionData(
        "swapExactTokensForETHSupportingFeeOnTransferTokens",
        [
          sellAmount,
          ethers.utils.parseEther("0"),
          sellPath,
          wallet,
          Date.parse(new Date()) / 1000 + 60 * 20,
        ]
      );
      let tx = {
        from: wallet,
        to: this.currentNetwork.router,
        gasLimit: ethers.utils.parseUnits(this.gasLimit + "", 0),
        data: d,
        value: ethers.utils.parseEther("0"),
      };
      if (this.gasPrice) {
        tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
      }
      try {
        const x = await w.sendTransaction(tx);
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `卖出交易已提交, hash: ${x.hash} 等待交易完成...`
        );
        await x.wait();
        this.log(`(${this.getShortAddr(wallet)})` + "本次卖出交易已完成!");
      } catch (e) {
        let t = "";
        if (e.reason) {
          t = e.reason;
        } else {
          t = JSON.stringify(e);
        }

        this.log(`(${this.getShortAddr(wallet)})` + "本次卖出交易失败 " + t);
      }
    },
    async transferTxToWallet(wallet, amount) {
      try {
        const p = this.getProvider();
        const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
        const r = await w.getBalance();
        if (r.toString() / 1e18 < amount) {
          this.log(
            `(${this.getShortAddr(wallet)})` +
              `余额不足, 请向钱包 ${this.currentNetwork.currentWallet} 中转移足够的` +
              this.currencyName
          );
          return false;
        }
        let tx = {
          to: wallet,
          value: ethers.utils.parseEther(amount + ""),
        };
        if (this.gasPrice) {
          tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
        }
        const x = await w.sendTransaction(tx);
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `转账交易已提交, hash: ${x.hash} 等待交易完成`
        );
        await x.wait();
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `已向钱包 ${wallet} 中发送 ${amount} ${this.currencyName} `
        );

        return true;
      } catch (e) {
        if (e.reason) {
          this.log(e.reason);
        } else {
          this.log(JSON.stringify(e));
        }
      }
    },
    getProvider() {
      if (this.rpc) {
        console.log("使用rpc", this.rpc);
        return new ethers.providers.JsonRpcProvider(this.rpc);
      } else {
        return toRaw(this.currentNetwork.provider);
      }
    },
    async handleBuyLogic(wallet, balances, privateKey) {
      // 生成要买入的数量
      // 判断余额是否充足, 不充足的话从第一个钱包中转入
      // 转入完成后开始买入
      // 买入完成后跳出, 等待下一次执行
      const amount =
        this.randomNum(
          parseFloat(this.buyAmountStart) * 100000000,
          parseFloat(this.buyAmountEnd) * 100000000
        ) / 100000000;
      this.log(
        `(${this.getShortAddr(wallet)})` +
          "本次买入金额为: " +
          amount +
          " " +
          this.currencyName
      );
      if (
        parseFloat(balances.bnbBalance) <
        parseFloat(amount) + parseFloat(this.transferAmount)
      ) {
        // 低于最低标准
        // 从第一个账号里转移钱进来
        this.log(
          `(${this.getShortAddr(wallet)})` + `当前钱包中余额不足, 即将补充...`
        );
        await this.transferTxToWallet(
          wallet,
          amount + parseFloat(this.transferAmount)
        );
      }
      const p = this.getProvider();
      const w = new ethers.Wallet(privateKey, p);
      // 执行买入操作
      let iface = new ethers.utils.Interface(dexAbi);
      let buyPath = [this.selectedCurrencyAddress, this.tokenAddress];
      // 判断买入路径
      if (
        this.selectedCurrencyAddress.toLowerCase() !==
        this.currencyAddress.toLowerCase()
      ) {
        buyPath = [
          this.currencyAddress,
          this.selectedCurrencyAddress,
          this.tokenAddress,
        ];
      }
      console.log(buyPath);

      let amountOutMin = ethers.utils.parseEther("0");
      // 如果设置了滑点
      if (parseFloat(this.buySlippage) > 0) {
        // 获取amountsOut
        const cc = new ethers.Contract(this.currentNetwork.router, dexAbi, p);
        const outs = await cc.getAmountsOut(
          ethers.utils.parseEther(parseFloat(amount).toFixed(16) + ""),
          buyPath
        );
        console.log(outs);
        amountOutMin = outs[outs.length - 1]
          .mul(BigNumber.from(100 - parseInt(this.buySlippage)))
          .div(BigNumber.from(100));
        // .then((res) => {
        //   console.log(res);
        //   amountOutMin = res[res.length - 1]
        //     .mul(BigNumber.from(100 - this.buySlippage))
        //     .div(BigNumber.from(100));
        // });
      }

      console.log("amountOutMin是:", amountOutMin);

      const d = iface.encodeFunctionData(
        // "swapExactETHForTokensSupportingFeeOnTransferTokens",
        "swapExactETHForTokens",
        [amountOutMin, buyPath, wallet, Date.parse(new Date()) / 1000 + 60 * 20]
      );
      let tx = {
        from: wallet,
        to: this.currentNetwork.router,
        gasLimit: ethers.utils.parseUnits(this.gasLimit, 0),
        data: d,
        value: ethers.utils.parseEther(parseFloat(amount).toFixed(16) + ""),
      };
      if (this.gasPrice) {
        tx["gasPrice"] = ethers.utils.parseUnits(this.gasPrice + "", 9);
      }
      try {
        const x = await w.sendTransaction(tx);
        this.log(
          `(${this.getShortAddr(wallet)})` +
            `买入交易已提交, hash: ${x.hash} 等待交易完成...`
        );
        await x.wait();
        this.log(`(${this.getShortAddr(wallet)})` + "本次买入交易已完成!");
      } catch (e) {
        let t = "";
        if (e.reason) {
          t = e.reason;
        } else {
          t = JSON.stringify(e);
        }
        this.log(
          `(${this.getShortAddr(wallet)})` + "本次买入交易失败, 跳过..." + t
        );
      }
    },
    async getTokenBalance(wallet) {
      // 根据地址获取
      const provider = this.getProvider();
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(this.tokenAddress, tokenAbi);
      const calls = [
        ethcallProvider.getEthBalance(wallet),
        token.balanceOf(wallet),
      ];
      const res = await ethcallProvider.all(calls);
      console.log(res);
      return {
        bnbBalance: res[0].toString() / 1e18,
        tokenBalance: res[1].toString() / Math.pow(10, this.tokenInfo.decimals),
        bnbBalanceBigNumber: res[0],
        tokenBalanceBigNumber: res[1],
      };
    },
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },

    log(str) {
      this.logs += "\n" + str;
      //   let logE = this.$refs["logInput"];
      //   logE.scrollTop(logE.scrollHeight - logE.height());
      this.$nextTick(() => {
        // console.log(this.logs);
        // console.log(
        //   this.$refs["logInput"].$refs.textarea.scrollTop,
        //   this.$refs["logInput"].$refs.textarea.scrollHeight,
        //   this.$refs["logInput"].$refs.textarea
        // );
        // this.$refs["logInput"].$refs.textarea.scrollTop =
        // this.$refs["logInput"].$refs.textarea.scrollHeight;
      });
    },
    async handleImportPrivateKeys() {
      console.log(this.tmpPrivateKeys);
      const keys = this.tmpPrivateKeys.split("\n");
      console.log(keys);
      // 根据私钥解析出公钥
      let list = [];
      keys.forEach((item) => {
        try {
          const w = new ethers.Wallet(item, null);
          console.log(w);
          list.push({
            publicKey: w.address,
            privateKey: item,
            balance: 0,
            tokenBalance: 0,
          });
        } catch (e) {
          return;
        }
      });
      this.list = list;
      this.tmpPrivateKeys = "";
      this.showImportWalletDialog = false;
      // 刷新余额
      this.saveWallets();
    },
    async copyAddress(address) {
      console.log(address);
      await this.$copyText(address);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    getShortAddr(address) {
      if (!address) return "";
      if (address.length >= 42) {
        return address.slice(0, 4) + "..." + address.slice(address.length - 5);
      }
      return address;
    },
    async exportWalletPublic() {
      const a = this.list
        .map((item) => {
          return item.publicKey;
        })
        .join("\n");
      await this.$copyText(a);
      this.$swal({
        title: "已复制所有公钥到剪贴板",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async exportWallets() {
      const a = this.list
        .map((item) => {
          return item.privateKey;
        })
        .join("\n");
      await this.$copyText(a);
      this.$swal({
        title: "已复制所有私钥到剪贴板",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async importWallets() {
      this.showImportWalletDialog = true;
    },
    async createWallets() {
      // 批量生成钱包数
      let text = "";
      if (this.list.length > 0) {
        text = "生成后将覆盖现有钱包, 请确保已保存当前刷单私钥或已清空刷单钱包";
      }
      const r = await this.$swal({
        title: "输入要生成的钱包数量",
        text,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
      console.log(r);
      if (!r.isConfirmed) return;
      const c = r.value;

      // const r = await this.$prompt("输入要生成的钱包数量");
      // if (r.action !== "confirm") return;
      // const c = r.value;
      let pks = []; // 公钥
      let prks = []; // 私钥

      this.list = [];
      for (let i = 0; i < parseInt(c); i++) {
        const wallet = ethers.Wallet.createRandom();
        console.log(wallet);
        const publicKey = wallet.address;
        const privateKey = wallet._signingKey().privateKey;
        pks.push(publicKey);
        prks.push(privateKey);
        // 添加数据
        this.list.push({
          publicKey,
          privateKey,
          balance: 0,
          tokenBalance: 0,
        });
        await this.$forceUpdate();
      }
      this.privateKeys = prks.join("\n");
      this.publicKeys = pks.join("\n");
      this.saveWallets();
    },
    async saveWallets() {
      localStorage.setItem(PL_KEYS, JSON.stringify(this.list));
    },
  },
};
</script>
<style lang="scss" scoped>
.config-p {
  margin-bottom: 10px;
}
.panel {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}
.background-white {
  background-color: #ffffff;
  border-radius: 10px;
}
.config-panel {
  // margin-top: 20px;
  &-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-split {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-item {
      flex: 3;
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  &-item {
    flex: 1;
    margin-right: 20px;
    &-body {
      margin-top: 8px;
    }
  }
}
.wallets-icons {
  display: flex;
  flex-direction: row;
  align-items: row;
}
.info-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tx-amount-wallets {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>